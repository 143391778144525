import React from "react";
import missionStatementImage from "../../Images/stock_image_5.jpeg";

function HomeMissionStatement() {
  return (
    <section className="home-mission-statement-container">
      <h2 className="section-header">WE CLEAN SO YOU DON'T HAVE TO</h2>
      <div className="home-mission-statement-info-container">
        <div className="home-mission-statement-image-container">
          <img
            className="home-mission-statement-image"
            src={missionStatementImage}
            alt=""
          />
        </div>
        <p className="home-mission-statement-paragraph">
          We are excited to introduce our exceptional cleaning services designed
          to make your life easier and your spaces spotless. At Blanco, we
          understand the importance of a clean and hygienic environment, whether
          it's your home, office, or commercial establishment.
          <br />
          <br />
          Our dedicated team of highly trained professionals is committed to
          delivering unparalleled cleaning solutions tailored to your unique
          needs. With years of industry experience, we have perfected our
          techniques and use state-of-the-art equipment and eco-friendly
          products to ensure outstanding results.
          <br />
          <br />
          From regular maintenance cleaning to deep cleaning projects, we take
          care of every aspect, leaving no corner untouched. Contact us now to
          schedule an appointment or to learn more about our services.
          <br />
          <br />
          We look forward to serving you and becoming your trusted cleaning
          partner.
        </p>
      </div>
    </section>
  );
}

export default HomeMissionStatement;
