import React from "react";
import AboutUsPhone from "../Components/AboutUsComponents/AboutUsPhone/AboutUsPhone";
import AboutOurWork from "../Components/AboutUsComponents/AboutOurWork/AboutOurWork";
import useScrollToTop from "../Lib/useScrollToTop";
import aboutUsImage from "../Images/stock_image_7.jpeg";

function AboutUs() {
  useScrollToTop();

  return (
    <main>
      <section className="about-us-container">
        <div className="about-us-image-container">
          <img
            className="about-us-image"
            src={aboutUsImage}
            alt="Pair of yellow gloves hanging from a clothing line"
          />
        </div>
        <div className="about-us-info-container">
          <h2 className="section-header about-us-header">ABOUT US</h2>
          <p className="about-us-info-paragraph">
            Welcome to Blanco, a cleaning company proudly owned and operated by
            minorities. We are dedicated to providing exceptional cleaning
            services while championing diversity and inclusivity. With multiple
            years of industry experience, we have built a reputation for
            delivering outstanding results and creating positive change in our
            community.
          </p>
          <p className="about-us-info-paragraph">
            At Blanco, we understand the unique challenges and barriers faced by
            minority-owned businesses. That's why we are committed to breaking
            down those barriers and creating opportunities for our team members
            and the community we serve. We believe in the power of
            representation and the value of diverse perspectives, which fuels
            our drive to excel in the cleaning industry.
          </p>
          <p className="about-us-info-paragraph">
            Thank you for considering Blanco for your cleaning needs. By
            choosing us, you support a minority-owned business that is committed
            to delivering exceptional service while making a positive impact.
            Contact us today to experience the difference that diversity,
            expertise, and a personal touch can bring to your cleaning
            experience.
          </p>
        </div>
      </section>
      <AboutUsPhone />
      <AboutOurWork />
    </main>
  );
}

export default AboutUs;
