import React from "react";
import { useState } from "react";

const DEEP_CLEANING = {
  packageText: [
    "Revitalize your living or working space with our comprehensive deep cleaning package. Our skilled team of professional cleaners will go above and beyond to ensure every corner of your home or office receives the thorough attention it deserves. From meticulously scrubbing floors to disinfecting high-touch surfaces, we leave no stone unturned in our pursuit of cleanliness. Say goodbye to accumulated dirt, grime, and allergens as we transform your environment into a pristine haven.",
    "With our advanced cleaning techniques and state-of-the-art equipment, we deliver exceptional results that exceed expectations. Our deep cleaning package is perfect for special occasions, seasonal cleaning, or whenever your space needs a fresh start. We use eco-friendly products that are safe for you, your loved ones, and the environment. Trust our experienced team to restore the beauty and hygiene of your space, creating a healthy and welcoming atmosphere. Experience the transformative power of our deep cleaning package and indulge in the satisfaction of a truly revitalized environment. Schedule your appointment today and let us bring new life to your surroundings."
  ],
  packageName: "DEEP CLEANING"
};
const MOVE_IN_MOVE_OUT = {
  packageText: [
    "Take the stress out of your move with our comprehensive moving day cleaning package. Whether you're leaving your old place or settling into a new one, our expert team is here to ensure a seamless transition. We'll handle all the cleaning details, leaving your space spotless and ready for the next chapter. From deep cleaning carpets to wiping down cabinets, we'll take care of every nook and cranny so you can focus on the logistics of your move.",
    "Our dedicated cleaners are trained to deliver exceptional results within tight timelines, ensuring your moving day goes smoothly. We understand the importance of leaving behind a clean home or starting fresh in a pristine space. With our advanced cleaning techniques and top-quality products, we'll tackle dust, dirt, and grime, leaving nothing but cleanliness behind. Trust our reliable and efficient team to make your moving day stress-free and leave you with a fresh start. Schedule our moving day cleaning package today and experience the convenience of a spotless space as you embark on your new journey."
  ],
  packageName: "MOVE IN/MOVE OUT"
};
const CUSTOM_CLEANING = {
  packageText: [
    "Discover the freedom of a custom cleaning package designed exclusively for you. At [Cleaning Company Name], we understand that every space is unique, and cleaning needs vary. That's why we offer a customizable cleaning solution tailored to your specific requirements. Whether you need specific areas prioritized, have special instructions, or prefer a flexible schedule, our experienced team will create a personalized cleaning plan just for you.",
    "From organizing clutter to deep cleaning hard-to-reach places, we'll address your specific cleaning concerns with utmost care and attention to detail. Our skilled cleaners utilize the latest techniques and eco-friendly products to ensure exceptional results without compromising your health or the environment. Experience the convenience and satisfaction of a cleaning service that aligns perfectly with your needs. Contact us today to discuss your custom cleaning package and let us take care of the cleaning while you enjoy a space that is tailored precisely to your liking"
  ],
  packageName: "CUSTOM CLEANING"
};

const PACKAGES = [DEEP_CLEANING, MOVE_IN_MOVE_OUT, CUSTOM_CLEANING];

function Packages() {
  const [activePackage, setActivePackage] = useState(DEEP_CLEANING);

  const renderPackages = () =>
    PACKAGES.map((p) => {
      const { packageName } = p;
      const isActive = activePackage.packageName === packageName;
      return (
        <h3
          key={packageName}
          className={`packages-tab-header ${isActive ? "active-package" : ""}`}
          onClick={() => setActivePackage(p)}
        >
          {packageName}
        </h3>
      );
    });

  const renderPackageText = () =>
    activePackage.packageText.map((text) => (
      <p className="package-text">{text}</p>
    ));

  return (
    <section className="packages-container">
      <h2 className="section-sub-header packages-header">PACKAGES</h2>
      <div className="packages-tab-header-container">{renderPackages()}</div>
      {renderPackageText()}
    </section>
  );
}

export default Packages;
