import React from "react";

function FormModal(props) {
  const { closeModal } = props;
  return (
    <div className="modal-container">
      <div className="modal">
        <i
          className="fa-solid fa-square-xmark drawer-x"
          onClick={closeModal}
        ></i>
        <i className="fa-solid fa-paper-plane section-header"></i>
        <h2 class="section-sub-header">Message Sent!</h2>
        <p>
          THANK YOU FOR REACHING OUT, WE WILL GET BACK TO YOU AS SOON AS
          POSSIBLE.
        </p>
        <button class="primary-button" onClick={closeModal}>
          Close
        </button>
      </div>
    </div>
  );
}

export default FormModal;
