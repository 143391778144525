import React from "react";

function Input(props) {
  const { input, onChange, error } = props;
  const { fieldName, value, valueOptions } = input;
  const placeHolderText = `PLEASE ENTER YOUR ${fieldName}`;

  const isSelect = fieldName === "NUMBER OF ROOMS THAT NEED CLEANING";
  const selectOptions = valueOptions?.map((v) => (
    <option key={v} value={v}>
      {v}
    </option>
  ));
  return (
    <div className="input-field-container">
      <label className="input-field-label" htmlFor={fieldName}>
        {fieldName}
      </label>
      {isSelect ? (
        <select
          value={value}
          className="input-field input-select"
          onChange={onChange}
        >
          <option value="" disabled selected>
            Select your option
          </option>
          {selectOptions}
        </select>
      ) : (
        <input
          className="input-field"
          type="text"
          value={value}
          onChange={onChange}
          id={fieldName}
          placeholder={placeHolderText}
        />
      )}
      {error && !input.value && (
        <span className="error-message">Please fill out field</span>
      )}
    </div>
  );
}

export default Input;
