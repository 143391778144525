import React from "react";
import { useState } from "react";
import Input from "./Input";
import axios from "axios";

const INITIAL_FORM_VALUES = [
  {
    fieldName: "FIRST NAME",
    value: ""
  },
  {
    fieldName: "LAST NAME",
    value: ""
  },
  {
    fieldName: "ADDRESS",
    value: ""
  },
  {
    fieldName: "CITY",
    value: ""
  },
  {
    fieldName: "ZIP POSTAL CODE",
    value: ""
  },
  {
    fieldName: "STATE",
    value: ""
  },
  {
    fieldName: "EMAIL",
    value: ""
  },
  {
    fieldName: "PHONE NUMBER",
    value: ""
  },
  {
    fieldName: "NUMBER OF ROOMS THAT NEED CLEANING",
    value: "",
    valueOptions: ["ONE", "TWO", "THREE", "FOUR", "FIVE +"]
  }
];

function ContactUsGrid(props) {
  const [values, setValues] = useState(INITIAL_FORM_VALUES);
  const [error, setError] = useState(false);
  const { openModal } = props;

  const renderInputs = () =>
    values.map((input, index) => {
      const handleChange = (e) =>
        setValues((prevValues) => {
          const newValues = [...prevValues];
          newValues.splice(index, 1, {
            ...prevValues[index],
            value: e?.target?.value
          });
          return newValues;
        });

      return (
        <Input
          key={input.fieldName + "input"}
          input={input}
          index={index}
          onChange={handleChange}
          error={error}
        />
      );
    });

  const handleSubmit = () => {
    setError(false);
    let hasError = false;
    values.forEach((field) => {
      if (!!!field.value || field.value?.length > 200) {
        hasError = true;
      }
    });

    if (!!!hasError) {
      const formValues = values.map((field) => field.value);
      axios
        .post(
          `https://public.herotofu.com/v1/b23d2300-e950-11ed-b24a-93241516dd10`,
          { ...formValues }
        )
        .catch((err) => console.log(err))
        .finally(() => {
          setValues(INITIAL_FORM_VALUES);
          openModal();
        });
    } else {
      setError(true);
    }
  };

  return (
    <>
      <form className="contact-us-grid-container">{renderInputs()}</form>
      <button
        className="secondary-button contact-form-button"
        onClick={handleSubmit}
      >
        SUBMIT
      </button>
    </>
  );
}

export default ContactUsGrid;
