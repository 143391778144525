import React from "react";
import BeforeAfter from "../BeforeAfter/BeforeAfter";
import style from "./AboutOurWork.module.css";

const PICTURES = [
  "1_before.jpg",
  "1_after.jpg",
  "2_before.jpg",
  "2_after.jpg",
  "3_before.PNG",
  "3_after.jpg"
];

function AboutOurWork() {
  const beforeAfterImages = PICTURES.map((imgName) => (
    <BeforeAfter imgName={imgName} />
  ));
  return (
    <section className={style.container}>
      <h2 className={`section-header ${style.header}`}>OUR WORK</h2>
      <div className={style.grid_container}>{beforeAfterImages}</div>
    </section>
  );
}

export default AboutOurWork;
