import React from "react";
import ServicesCard from "./ServicesCard";
import cardImage1 from "../../Images/stock_image_3.jpeg";
import cardImage2 from "../../Images/stock_image_2.jpeg";
import cardImage3 from "../../Images/stock_image_1.jpeg";

const CARD_ONE = {
  image: cardImage1,
  altText: "",
  serviceText:
    "Are you tired of spending your precious free time cleaning? Let our professional cleaning team take care of it all! Treat yourself to a one-time cleaning service that will leave your home or office sparkling from top to bottom. Sit back, relax, and enjoy a fresh, pristine space without lifting a finger.",
  serviceName: "ONE TIME CLEANING"
};

const CARD_TWO = {
  image: cardImage2,
  altText: "",
  serviceText:
    "Maintain a consistently clean and healthy environment with our reliable recurring cleaning services. Say goodbye to the hassle of cleaning and hello to a consistently fresh and inviting environment. Contact us today to schedule your recurring cleaning service and discover the difference of a consistently fresh environment.",
  serviceName: "RECURRING CLEANING"
};

const CARD_THREE = {
  image: cardImage3,
  altText: "",
  serviceText:
    "Experience the ultimate in personalized cleaning. Our custom cleaning service caters to your exact needs and preferences. From specific instructions to targeted areas, we create a cleaning plan that's uniquely tailored to you. Contact us today for a truly customized cleaning experience.",
  serviceName: "CUSTOM CLEANING"
};

function ServicesCardContainer() {
  return (
    <section className="our-services-card-container">
      <ServicesCard card={CARD_ONE} />
      <ServicesCard card={CARD_TWO} />
      <ServicesCard card={CARD_THREE} />
    </section>
  );
}

export default ServicesCardContainer;
