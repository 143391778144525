import React from "react";
import style from "./AboutUsPhone.module.css";

function AboutUsPhone() {
  return (
    <section className={style.container}>
      <h2 className={style.header}>GIVE US A CALL</h2>
      <span className={style.phone_number}>+1 (612) 323-9287</span>
    </section>
  );
}

export default AboutUsPhone;
