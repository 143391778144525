import React from "react";
import style from "./BeforeAfter.module.css";

function BeforeAfter(props) {
  const { imgName } = props;
  const description = imgName?.split("_")?.[1]?.split(".")?.[0];

  return (
    <div className={style.container}>
      <img
        src={process.env.PUBLIC_URL + `/images/Blanco_before_after/${imgName}`}
        className={style.image}
        alt="before after images"
      />
      <span>{description}</span>
    </div>
  );
}

export default BeforeAfter;
