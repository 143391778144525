import React from "react";
import { useNavigate } from "react-router-dom";
import jumbotronImage from "../../Images/stock_image_6.jpeg";

function HomeJumbotron() {
  const navigate = useNavigate();
  const handleButtonClick = () => navigate("/contactUs");

  return (
    <section className="home-jumbotron-container">
      <img
        className="home-jumbotron-image"
        src={jumbotronImage}
        alt="Lady Standing In Kitchen"
      />
      <div className="home-jumbotron-text-container">
        <h1 className="section-header home-jumbotron-header">BLANCO</h1>
        <h3>Cleaning Excellence, Beyond Compare!</h3>
      </div>
      <div className="home-jumbotron-button-container">
        <button
          className="secondary-button jumbotron-button"
          onClick={handleButtonClick}
        >
          GET QUOTE
        </button>
      </div>
    </section>
  );
}

export default HomeJumbotron;
