import React from "react";
import ContactUsGrid from "./ContactUsGrid";

function ContactUsForm(props) {
  const { openModal } = props;

  return (
    <section className="contact-us-form-container">
      <h1 className="section-header contact-us-header">CONTACT US</h1>
      <ContactUsGrid openModal={openModal} />
    </section>
  );
}

export default ContactUsForm;
