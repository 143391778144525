import React from "react";

function ServicesCard(props) {
  const { card } = props;
  const { image, altText, serviceName, serviceText } = card;

  return (
    <div className="service-card">
      <img className="service-card-image" src={image} alt={altText} />
      <h3 className="service-card-title">{serviceName}</h3>
      <p className="service-card-paragraph">{serviceText}</p>
    </div>
  );
}

export default ServicesCard;
