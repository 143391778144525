import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../Images/BlancoSVG/Blanco_Logo_Primary.svg";

function Header(props) {
  const { openDrawer } = props;
  const handleActiveItem = ({ isActive }) => (isActive ? "selected-nav" : "");
  const handleLogoClick = () => (window.location.href = "/");

  return (
    <div className="header-container">
      <img
        src={logo}
        className="header-logo"
        onClick={handleLogoClick}
        alt="Pink Blanco Logo"
      />
      <nav>
        <ul className="header-list">
          <li className="header-list-item">
            <NavLink to="/services" className={handleActiveItem}>
              SERVICES
            </NavLink>
          </li>
          <li className="header-list-item">
            <NavLink to="/aboutUs" className={handleActiveItem}>
              ABOUT US
            </NavLink>
          </li>
          <li className="header-list-item">
            <NavLink to="/contactUs" className={handleActiveItem}>
              CONTACT US
            </NavLink>
          </li>
        </ul>
        <i className="fa-solid fa-bars hamburger-nav" onClick={openDrawer}></i>
      </nav>
    </div>
  );
}

export default Header;
