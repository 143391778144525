import React from "react";
import map from "../../Images/ServiceMap.png";

function AreaWeServe() {
  return (
    <section className="areas-we-serve-container">
      <h2 className="section-sub-header areas-we-serve-header">
        AREA WE SERVE
      </h2>
      <div className="areas-we-serve-image-container">
        <img
          className="areas-we-serve-image"
          src={map}
          alt="Map of Minneapolis, Minnesota"
        />
      </div>
    </section>
  );
}

export default AreaWeServe;
