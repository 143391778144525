import "./index.css";
// import Header from "./Components/CommonComponents/Header";
import Screens from "./Components/CommonComponents/Screens";
// import Footer from "./Components/CommonComponents/Footer";

function App() {
  return <Screens />;
}

export default App;
