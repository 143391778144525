import React from "react";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import logoImage from "../../Images/BlancoSVG/Blanco_Logo_Primary.svg";

function Drawer(props) {
  const { closeDrawer } = props;

  const handleLogoClick = () => (window.location.href = "/");
  const handleActiveItem = ({ isActive }) =>
    isActive ? "selected-nav react-nav-link" : "react-nav-link";
  const handleClose = (e) => {
    const target = e.target;
    const classes = target.classList || [];

    if (classes[0] === "drawer-background") {
      closeDrawer();
    }
  };

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth > 768 && closeDrawer()
    );
    return window.removeEventListener(
      "resize",
      () => window.innerWidth > 768 && closeDrawer()
    );
  }, []);

  return (
    <div className="drawer-background" onClick={handleClose}>
      <div className="drawer-container">
        <img
          src={logoImage}
          className="drawer-logo"
          onClick={handleLogoClick}
          alt="Pink Blanco Logo"
        />
        <i className="fa-solid fa-xmark drawer-x" onClick={closeDrawer}></i>
        <ul className="drawer-list">
          <li className="header-list-item" onClick={closeDrawer}>
            <NavLink to="/services" className={handleActiveItem}>
              SERVICES
            </NavLink>
          </li>
          <li className="header-list-item" onClick={closeDrawer}>
            <NavLink to="/aboutUs" className={handleActiveItem}>
              ABOUT US
            </NavLink>
          </li>
          <li className="header-list-item" onClick={closeDrawer}>
            <NavLink to="/contactUs" className={handleActiveItem}>
              CONTACT US
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Drawer;
