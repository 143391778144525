import React from "react";
import ServicesCardContainer from "../Components/ServicesComponents/ServicesCardContainer";
import Packages from "../Components/ServicesComponents/Packages";
import AreaWeServe from "../Components/ServicesComponents/AreaWeServe";
import useScrollToTop from "../Lib/useScrollToTop";

function Services() {
  useScrollToTop();

  return (
    <main>
      <h1 className="section-header our-services-header">OUR SERVICES</h1>
      <ServicesCardContainer />
      <Packages />
      <AreaWeServe />
    </main>
  );
}

export default Services;
