import React from "react";
import { useState } from "react";
import ContactUsJumbotron from "../Components/ContactUsComponents/ContactUsJumbotron";
import ContactUsForm from "../Components/ContactUsComponents/ContactUsForm";
import FormModal from "../Components/ContactUsComponents/FormModal";
import useScrollToTop from "../Lib/useScrollToTop";

function ContactUs() {
  useScrollToTop();

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <main>
      <ContactUsJumbotron />
      <ContactUsForm openModal={openModal} />
      {modalOpen && <FormModal closeModal={closeModal} />}
    </main>
  );
}

export default ContactUs;
