import React from "react";
import { useNavigate } from "react-router-dom";

function HomeBookAppointment() {
  const navigate = useNavigate();
  const handleButtonClick = () => navigate("/contactUs");

  return (
    <section className="home-book-appointment-container">
      <h2 className="section-sub-header home-book-appointment-header">
        GET A QUOTE TODAY
      </h2>
      <button
        className="secondary-button home-book-appointment-button"
        onClick={handleButtonClick}
      >
        GET QUOTE
      </button>
    </section>
  );
}

export default HomeBookAppointment;
