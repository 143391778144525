import React from "react";
import HomeJumbotron from "../Components/HomeComponents/HomeJumbotron";
import HomeMissionStatement from "../Components/HomeComponents/HomeMissionStatement";
import HomeBookAppointment from "../Components/HomeComponents/HomeBookAppointment";
import useScrollToTop from "../Lib/useScrollToTop";

function Home() {
  useScrollToTop();

  return (
    <main>
      <HomeJumbotron />
      <HomeMissionStatement />
      <HomeBookAppointment />
    </main>
  );
}

export default Home;
