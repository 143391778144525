import React from "react";
import jumbotronImage from "../../Images/stock_image_4.jpeg";
function ContactUsJumbotron() {
  return (
    <section className="contact-us-jumbotron-container">
      <img
        className="home-jumbotron-image"
        src={jumbotronImage}
        alt="Lady Standing In Kitchen"
      />
    </section>
  );
}

export default ContactUsJumbotron;
